// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'



const firebaseConfig = {
  apiKey: "AIzaSyDk3K7irqEGAmLzDngEbr1dB2IsLQmdGY8",
  authDomain: "sportzieapp-dev-b0ad1.firebaseapp.com",
  projectId: "sportzieapp-dev-b0ad1",
  storageBucket: "sportzieapp-dev-b0ad1.appspot.com",
  messagingSenderId: "7620736556",
  appId: "1:7620736556:web:263700743b6dad1008c389",
  measurementId: "G-QY2VJ1T17Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth()


export { app, auth }
