var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboards',
          icon: 'ni ni-shop text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', path: '/dashboard' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Alternative', path: '/alternative' }}})],1),_c('sidebar-item',{attrs:{"link":{
                name: 'Examples',
                icon: 'ni ni-ungroup text-orange'
                }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Pricing', path: '/pricing' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Login', path: '/login' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Register', path: '/register' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Lock', path: '/lock' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Timeline', path: '/pages/timeline' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Profile', path: '/pages/user' }}})],1),_c('sidebar-item',{attrs:{"link":{
                name: 'Components',
                icon: 'ni ni-ui-04 text-info'
              }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Buttons', path: '/components/buttons' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Cards', path: '/components/cards' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Grid', path: '/components/grid-system' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Notifications', path: '/components/notifications' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Icons', path: '/components/icons' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Typography', path: '/components/typography' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Multi Level' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Third level menu', path:'#!' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Just another link', path:'#a' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'One last link', path:'#b' }}})],1)],1),_c('sidebar-item',{attrs:{"link":{
                      name: 'Forms',
                      icon: 'ni ni-single-copy-04 text-pink'
                    }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Elements', path: '/forms/elements' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Components', path: '/forms/components' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Validation', path: '/forms/validation' }}})],1),_c('sidebar-item',{attrs:{"link":{
                      name: 'Tables',
                      icon: 'ni ni-align-left-2 text-default'
                    }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Tables', path: '/tables/regular' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Sortable', path: '/tables/sortable' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Paginated Tables', path: '/tables/paginated' }}})],1),_c('sidebar-item',{attrs:{"link":{
                      name: 'Maps',
                      icon: 'ni ni-map-big text-primary'
                    }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Google', path: '/maps/google' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Vector', path: '/maps/vector' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Widgets',
          icon: 'ni ni-archive-2 text-green',
          path: '/widgets'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Charts',
          icon: 'ni ni-chart-pie-35 text-info',
          path: '/charts'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Calendar',
          icon: 'ni ni-calendar-grid-58 text-red',
          path: '/calendar'
        }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Documentation")]),_c('b-nav',{staticClass:"navbar-nav mb-md-3"},[_c('b-nav-item',{attrs:{"href":"https://sportzie.io/learning-lab/bootstrap-vue/quick-start/argon-dashboard"}},[_c('i',{staticClass:"ni ni-spaceship"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Getting started")])],1),_c('b-nav-item',{attrs:{"href":"https://sportzie.io/learning-lab/bootstrap-vue/colors/argon-dashboard"}},[_c('i',{staticClass:"ni ni-palette"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Foundation")])],1),_c('b-nav-item',{attrs:{"href":"https://sportzie.io/learning-lab/bootstrap-vue/avatar/argon-dashboard"}},[_c('i',{staticClass:"ni ni-ui-04"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Components")])],1),_c('b-nav-item',{attrs:{"href":"https://sportzie.io/learning-lab/bootstrap-vue/charts/argon-dashboard"}},[_c('i',{staticClass:"ni ni-chart-pie-35"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Plugins")])],1)],1)],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }