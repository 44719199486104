// import { createStore } from 'vuex'
import { auth } from './firebaseConfig'
import {createUserWithEmailAndPassword,signInWithEmailAndPassword,signOut,sendEmailVerification} from 'firebase/auth'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store =  new Vuex.Store({
  state: {
        user: {
            loggedIn: false,
            data: null
          }
  },

  getters: {
    user(state){
      return state.user
    }
  },
  mutations: {
    SET_USER(state, payload) {
      state.user.data = payload
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    }
  },
  actions: {
      async register(context, { email, password, name}){
          const response = await createUserWithEmailAndPassword(auth, email, password)
          if (response) {
              context.commit('SET_USER', response.user)
              await sendEmailVerification(auth.currentUser)
              .then(() => {
                  // Email verification sent!
                  console.log('Email Verification sent! Check your mail box');
                  // ...
              });
              //response.user.updateProfile({displayName: name})
          } else {
              throw new Error('Unable to register user')
          }
      },

      async logIn(context, { email, password }){
        const response = await signInWithEmailAndPassword(auth, email, password)
        if (response) {
            context.commit('SET_USER', response.user)
        } else {
            throw new Error('login failed')
        }
    },

    async logOut(context){
        await signOut(auth)
        context.commit('SET_USER', null)
    },

    async fetchUser(context ,user) {
      console.log("fetching user")
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER", {
          displayName: user.displayName,
          email: user.email
        });
      } else {
        context.commit("SET_USER", null);
      }
  }
  }
  })

  export default store;
